import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaDollarSign, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import { useReanimateOnScroll } from '../../hooks/useReanimateOnScroll';

const Section = styled.section`
  padding: 5rem 2rem;
  background: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ContentBlock = styled(motion.div)`
  margin-bottom: 4rem;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const Subtitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const Text = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const StatCard = styled(motion.div)`
  text-align: center;
  padding: 2rem;
  background: ${props => props.theme.colors.lightGray};
  border-radius: 8px;
`;

const StatIcon = styled.div`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1rem;
`;

const StatTitle = styled.h4`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const StatText = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
`;

const LocationBlock = styled(motion.div)`
  margin: 4rem 0;
`;

const LocationContent = styled.div`
  padding: 2rem;
  background: ${props => props.theme.colors.lightGray};
  border-radius: 8px;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0 -1rem;
  }
`;

const AboutUs = () => {
  const [ref1, controls1] = useReanimateOnScroll(0.2);
  const [ref2, controls2] = useReanimateOnScroll(0.2);

  const fadeInVariants = {
    hidden: { 
      opacity: 0,
      y: 30
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const scaleInVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.8
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  return (
    <Section>
      <Container>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={fadeInVariants}
        >
          <ContentBlock>
            <Title>About Us</Title>
            <Subtitle>Strategic Business Consulting Solutions</Subtitle>
            <Text>
              Spruce Consulting is one of the nation's fastest-growing business consulting firms, 
              helping organizations optimize their operations and achieve sustainable growth. Our 
              management team brings decades of combined consulting experience, and as former 
              business owners ourselves, we understand the challenges of running a business.
            </Text>
            <Text>
              By offering funding against a business' future receivables, we help businesses that are 
              typically turned away by traditional banks obtain the working capital they need. Whether 
              it's for inventory, paying off tax liens, new hires, or making payroll, Spruce Consulting 
              is your trusted alternative lending source.
            </Text>
          </ContentBlock>

          <ContentBlock>
            <Subtitle>Our Consulting Process</Subtitle>
            <Text>
              We offer a comprehensive consulting approach that begins with understanding your business needs. 
              Through detailed analysis and strategic planning, we develop customized solutions that drive 
              measurable results. Our process is transparent, collaborative, and focused on delivering 
              long-term value.
            </Text>
          </ContentBlock>
        </motion.div>

        <motion.div
          ref={ref2}
          animate={controls2}
          initial="hidden"
          variants={scaleInVariants}
        >
          <LocationBlock>
            <LocationContent>
              <Subtitle>Our Unique Approach</Subtitle>
              <Text>
                Unlike traditional consulting firms concentrated in crowded urban centers, 
                Spruce Consulting brings a fresh perspective to business strategy. Our approach 
                reflects our commitment to sustainable growth and long-term success.
              </Text>
              <Text>
                We believe in building lasting partnerships with our clients, focusing on 
                practical solutions that deliver real results. Our team brings deep industry 
                expertise combined with innovative thinking to help businesses thrive in 
                today's competitive environment.
              </Text>
              <Text>
                By maintaining a focused, relationship-driven approach, we ensure that each 
                client receives personalized attention and strategies tailored to their 
                specific needs and goals.
              </Text>
            </LocationContent>
          </LocationBlock>

          <Text>
            Unlike traditional financial institutions concentrated in crowded urban centers, Spruce Consulting proudly calls New York home. Situated along the historic Hudson River, where commerce and nature have coexisted for centuries, our location reflects our different approach to business funding.
          </Text>

          <StatsGrid>
            <StatCard>
              <StatIcon><FaCheckCircle /></StatIcon>
              <StatTitle>Industry Leader</StatTitle>
              <StatText>in Business Consulting</StatText>
            </StatCard>

            <StatCard>
              <StatIcon><FaChartLine /></StatIcon>
              <StatTitle>500+</StatTitle>
              <StatText>Successful Projects</StatText>
            </StatCard>

            <StatCard>
              <StatIcon><FaShieldAlt /></StatIcon>
              <StatTitle>95%</StatTitle>
              <StatText>Client Satisfaction Rate</StatText>
            </StatCard>

            <StatCard>
              <StatIcon><FaDollarSign /></StatIcon>
              <StatTitle>Proven ROI</StatTitle>
              <StatText>for Our Clients</StatText>
            </StatCard>
          </StatsGrid>
        </motion.div>
      </Container>
    </Section>
  );
};

export default AboutUs; 