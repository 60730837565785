import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { formatCurrency, parseCurrency } from '../../utils/formatters';
import LoadingOverlay from '../shared/LoadingOverlay';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { sendApplicationEmail } from '../../utils/emailService';
import { US_STATES } from '../../utils/constants';

const Section = styled.section`
  padding: 5rem 2rem;
  background: white;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Form = styled.form`
  background: ${props => props.theme.colors.lightGray};
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 1.5rem;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-bottom: 1rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 16px; // Prevents iOS zoom on focus
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background: white;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: ${props => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.accent};
  }
`;

const CheckboxContainer = styled.div`
  margin: 2rem 0;
  padding: 1.5rem;
  background: white;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  line-height: 1.5;
  color: ${props => props.theme.colors.text};
`;

const Checkbox = styled.input`
  margin-top: 0.25rem;
  cursor: pointer;
`;

const initialFormState = {
  // Business Information
  businessName: '',
  industry: '',
  businessStartDate: '',
  ein: '',
  monthlyRevenue: '',
  desiredAmount: '',
  businessAddress: '',
  businessCity: '',
  businessState: '',
  businessZip: '',

  // Personal Information
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  socialSecurityNumber: '',
  email: '',
  phone: '',
  homeAddress: '',
  homeCity: '',
  homeState: '',
  homeZip: '',

  // New field
  ownershipPercentage: '',
};

const ApplicationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [partnerId, setPartnerId] = useState(null);
  const [mounted, setMounted] = useState(true);

  const [formData, setFormData] = useState(() => {
    // Try to get stored data
    const storedData = localStorage.getItem('applicationData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Clear stored data after retrieving
      localStorage.removeItem('applicationData');
      return {
        ...initialFormState,
        ...parsedData,
        monthlyRevenue: formatCurrency(parsedData.monthlyRevenue || '0')
      };
    }
    return initialFormState;
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    // Check for partner ID in URL params
    const params = new URLSearchParams(location.search);
    const pid = params.get('pid');
    if (pid) {
      setPartnerId(pid);
    }
  }, [location]);

  useEffect(() => {
    // Get IP address
    const getIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error getting IP:', error);
      }
    };
    
    getIP();
  }, []);

  useEffect(() => {
    // Cleanup function
    return () => {
      setMounted(false);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    const errors = {};
    
    // Required fields validation
    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    }
    
    if (!formData.phone?.trim()) {
      errors.phone = 'Phone number is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mounted) return;
    console.log('Form submission started');
    setIsSubmitting(true);
    setError('');

    try {
      // Send email with just the form data
      const emailResponse = await sendApplicationEmail(formData, ipAddress);
      console.log('Email sent successfully:', emailResponse);

      // Success - Set state before navigation
      setSubmitSuccess(true);
      console.log('Form submitted successfully');
      
      // Add a small delay before navigation
      setTimeout(() => {
        navigate('/thank-you', { replace: true });
      }, 100);

    } catch (error) {
      console.error('Submission error details:', error);
      if (mounted) {
        setError('An error occurred while submitting your application. Please try again.');
      }
    } finally {
      if (mounted) {
        setIsSubmitting(false);
      }
    }
  };

  const handleMonthlyRevenueChange = (e) => {
    const formatted = formatCurrency(e.target.value);
    setFormData({
      ...formData,
      monthlyRevenue: formatted
    });
  };

  // Add a custom handler for ownership percentage
  const handleOwnershipChange = (e) => {
    let value = parseInt(e.target.value);
    
    // Ensure value is between 1 and 100
    if (value < 1) value = 1;
    if (value > 100) value = 100;
    
    setFormData({
      ...formData,
      ownershipPercentage: value
    });
  };

  return (
    <Section>
      {isSubmitting && <LoadingOverlay />}
      <Container>
        <Header>
          <Title>Start Your Growth Journey</Title>
          <Description>
            Spruce Consulting's application process is quick and straightforward. 
            Complete the form below and our team will review your information within 24 hours.
          </Description>
        </Header>

        <Form onSubmit={handleSubmit}>
          {submitSuccess && (
            <SuccessMessage>
              Thank you for your application! A Spruce Consulting representative will contact you shortly.
            </SuccessMessage>
          )}

          {submitError && (
            <ErrorMessage>{submitError}</ErrorMessage>
          )}

          <FormGroup>
            <Label htmlFor="businessName">Business Name</Label>
            <Input
              type="text"
              id="businessName"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="dateOfBirth">Date of Birth</Label>
            <Input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="socialSecurityNumber">Social Security Number</Label>
            <Input
              type="text"
              id="socialSecurityNumber"
              name="socialSecurityNumber"
              value={formData.socialSecurityNumber}
              onChange={handleChange}
              placeholder="XXX-XX-XXXX"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="email">Email *</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="phone">Phone Number *</Label>
            <Input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="monthlyRevenue">Monthly Revenue</Label>
            <Input
              type="text"
              name="monthlyRevenue"
              placeholder="Monthly Revenue"
              value={formData.monthlyRevenue}
              onChange={handleMonthlyRevenueChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="desiredAmount">Desired Funding Amount</Label>
            <Select
              id="desiredAmount"
              name="desiredAmount"
              value={formData.desiredAmount}
              onChange={handleChange}
              required
            >
              <option value="">Select Amount Needed</option>
              <option value="10000-25000">$10,000 - $25,000</option>
              <option value="25000-50000">$25,000 - $50,000</option>
              <option value="50000-100000">$50,000 - $100,000</option>
              <option value="100000-250000">$100,000 - $250,000</option>
              <option value="250000-500000">$250,000 - $500,000</option>
              <option value="500000+">$500,000+</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="industry">Industry</Label>
            <Select
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required
            >
              <option value="">Select Industry</option>
              <option value="Retail">Retail</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Construction">Construction</option>
              <option value="Transportation">Transportation</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Other">Other</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="businessStartDate">Business Start Date</Label>
            <Input
              type="date"
              id="businessStartDate"
              name="businessStartDate"
              value={formData.businessStartDate}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="ein">EIN (Employer Identification Number)</Label>
            <Input
              type="text"
              id="ein"
              name="ein"
              value={formData.ein}
              onChange={handleChange}
              placeholder="XX-XXXXXXX"
              required
            />
          </FormGroup>

          {/* Business Address Fields */}
          <FormGroup>
            <Label htmlFor="businessAddress">Business Address</Label>
            <Input
              type="text"
              id="businessAddress"
              name="businessAddress"
              value={formData.businessAddress}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
            <FormGroup>
              <Label htmlFor="businessCity">City</Label>
              <Input
                type="text"
                id="businessCity"
                name="businessCity"
                value={formData.businessCity}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="businessState">State</Label>
              <Select
                id="businessState"
                name="businessState"
                value={formData.businessState}
                onChange={handleChange}
                required
              >
                <option value="">Select State</option>
                {US_STATES.map(state => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="businessZip">ZIP Code</Label>
              <Input
                type="text"
                id="businessZip"
                name="businessZip"
                value={formData.businessZip}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </div>

          {/* Personal Address Fields */}
          <FormGroup>
            <Label htmlFor="homeAddress">Home Address</Label>
            <Input
              type="text"
              id="homeAddress"
              name="homeAddress"
              value={formData.homeAddress}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
            <FormGroup>
              <Label htmlFor="homeCity">City</Label>
              <Input
                type="text"
                id="homeCity"
                name="homeCity"
                value={formData.homeCity}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="homeState">State</Label>
              <Select
                id="homeState"
                name="homeState"
                value={formData.homeState}
                onChange={handleChange}
                required
              >
                <option value="">Select State</option>
                {US_STATES.map(state => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="homeZip">ZIP Code</Label>
              <Input
                type="text"
                id="homeZip"
                name="homeZip"
                value={formData.homeZip}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Label htmlFor="ownershipPercentage">Ownership Percentage</Label>
            <Input
              type="number"
              id="ownershipPercentage"
              name="ownershipPercentage"
              value={formData.ownershipPercentage}
              onChange={handleOwnershipChange}
              min="1"
              max="100"
              placeholder="Enter percentage (1-100)"
              required
              style={{ width: '150px' }}  // Make the input field shorter
            />
          </FormGroup>

          <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                required
              />
              <span>
                By checking this box, I confirm that all information provided is accurate and true. 
                I authorize Spruce Consulting to verify all submitted information and perform any 
                necessary credit checks. I understand that Spruce Consulting and its third-party 
                affiliates may contact me via phone, text message, or email regarding my application. 
                I understand this serves as my electronic signature for this application and consent 
                to receive communications.
              </span>
            </CheckboxLabel>
          </CheckboxContainer>

          <SubmitButton
            type="submit"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            disabled={!isAgreed || isSubmitting}
            onClick={() => console.log('Submit button clicked', { isAgreed, isSubmitting })}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Application'}
          </SubmitButton>
        </Form>
      </Container>
    </Section>
  );
};

// Add styled components for success/error messages
const SuccessMessage = styled(motion.div)`
  background: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

const ErrorMessage = styled(motion.div)`
  background: #f8d7da;
  color: #721c24;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

export default ApplicationForm; 