const theme = {
  colors: {
    primary: '#2C5530',    // Deep Forest Green
    secondary: '#4A8B2C',  // Vibrant Forest Green
    accent: '#1D3B23',     // Dark Forest Green
    white: '#FFFFFF',
    lightGray: '#F5F7FA',
    text: '#333333'
  },
  fonts: {
    primary: "'Poppins', sans-serif",
    secondary: "'Open Sans', sans-serif"
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px'
  }
};

export default theme; 