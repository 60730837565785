import emailjs from '@emailjs/browser';
import { US_STATES } from './constants';

// Replace these with your actual EmailJS credentials
const EMAIL_SERVICE_ID = 'service_fklrf4q';    // Gmail service
const EMAIL_TEMPLATE_ID = 'template_af55hzv';  // Your template
const EMAIL_USER_ID = '3dLxkiqBOpmUc3ne5';    // Your public key

// Initialize EmailJS
emailjs.init(EMAIL_USER_ID);

const calculateTimeInBusiness = (startDate) => {
  const start = new Date(startDate);
  const now = new Date();
  const years = now.getFullYear() - start.getFullYear();
  const months = now.getMonth() - start.getMonth();
  const totalMonths = (years * 12) + months;

  if (totalMonths < 12) {
    return `${totalMonths} months`;
  }
  return `${Math.floor(totalMonths/12)} years ${totalMonths%12} months`;
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

const formatCurrency = (amount) => {
  if (!amount) return 'Not specified';
  if (amount === '500000+') return '$500,000+';
  
  const [min, max] = amount.split('-');
  const formatNumber = num => `$${parseInt(num).toLocaleString()}`;
  
  if (max) {
    return `${formatNumber(min)} - ${formatNumber(max)}`;
  }
  return formatNumber(min);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const sendApplicationEmail = async (formData, ipAddress) => {
  try {
    const getStateName = (stateCode) => {
      const state = US_STATES.find(s => s.value === stateCode);
      return state ? state.label : stateCode;
    };

    const message = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
        <!-- Header -->
        <div style="background: #2C5530; color: white; padding: 30px; border-radius: 8px; text-align: center; margin-bottom: 30px;">
          <img src="https://i.imgur.com/Erw0F7Z.png" alt="Spruce Consulting" style="width: 200px; margin-bottom: 15px;">
          <h1 style="margin: 0; font-size: 24px;">${formData.firstName} ${formData.lastName}</h1>
          <p style="margin: 10px 0 0; font-size: 18px;">${formData.businessName}</p>
        </div>

        <!-- Business Information -->
        <div style="background: #F5F7FA; padding: 25px; border-radius: 8px; margin-bottom: 20px;">
          <h2 style="color: #2C5530; margin: 0 0 20px; padding-bottom: 10px; border-bottom: 2px solid #4A8B2C;">
            Business Information
          </h2>
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td style="padding: 8px 0; color: #666;">Business Name:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.businessName}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Industry:</td>
              <td style="padding: 8px 0; font-weight: 500;">${capitalizeFirstLetter(formData.industry)}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Business Start Date:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formatDate(formData.businessStartDate)}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Time in Business:</td>
              <td style="padding: 8px 0; font-weight: 500;">${calculateTimeInBusiness(formData.businessStartDate)}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">EIN:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.ein}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Monthly Revenue:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.monthlyRevenue}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Desired Funding:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formatCurrency(formData.desiredAmount)}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Ownership Percentage:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.ownershipPercentage}%</td>
            </tr>
          </table>

          <div style="background: white; margin-top: 20px; padding: 15px; border-radius: 4px;">
            <h3 style="color: #2C5530; margin: 0 0 10px; font-size: 16px;">Business Address</h3>
            <p style="margin: 0; line-height: 1.5;">
              ${formData.businessAddress}<br>
              ${formData.businessCity}, ${getStateName(formData.businessState)} ${formData.businessZip}
            </p>
          </div>
        </div>

        <!-- Personal Information -->
        <div style="background: #F5F7FA; padding: 25px; border-radius: 8px; margin-bottom: 20px;">
          <h2 style="color: #2C5530; margin: 0 0 20px; padding-bottom: 10px; border-bottom: 2px solid #4A8B2C;">
            Personal Information
          </h2>
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td style="padding: 8px 0; color: #666;">Name:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.firstName} ${formData.lastName}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Date of Birth:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formatDate(formData.dateOfBirth)}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">SSN:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.socialSecurityNumber}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Email:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.email}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Phone:</td>
              <td style="padding: 8px 0; font-weight: 500;">${formData.phone}</td>
            </tr>
          </table>

          <div style="background: white; margin-top: 20px; padding: 15px; border-radius: 4px;">
            <h3 style="color: #2C5530; margin: 0 0 10px; font-size: 16px;">Home Address</h3>
            <p style="margin: 0; line-height: 1.5;">
              ${formData.homeAddress}<br>
              ${formData.homeCity}, ${getStateName(formData.homeState)} ${formData.homeZip}
            </p>
          </div>
        </div>

        <!-- Additional Information -->
        <div style="background: #F5F7FA; padding: 25px; border-radius: 8px;">
          <h2 style="color: #2C5530; margin: 0 0 20px; padding-bottom: 10px; border-bottom: 2px solid #4A8B2C;">
            Additional Information
          </h2>
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td style="padding: 8px 0; color: #666;">IP Address:</td>
              <td style="padding: 8px 0; font-weight: 500;">${ipAddress}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Submission Date:</td>
              <td style="padding: 8px 0; font-weight: 500;">${new Date().toLocaleString()}</td>
            </tr>
            <tr>
              <td style="padding: 8px 0; color: #666;">Terms Accepted:</td>
              <td style="padding: 8px 0; font-weight: 500;">Yes</td>
            </tr>
          </table>
        </div>
      </div>
    `;

    return await emailjs.send(
      EMAIL_SERVICE_ID,
      EMAIL_TEMPLATE_ID,
      {
        to_email: 'admin@spruce-consulting.com',
        from_name: formData.businessName || 'New Application',
        subject: `New Application from ${formData.businessName}`,
        message
      }
    );

  } catch (error) {
    console.error('Email service error:', error);
    throw error;
  }
}; 